import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import FingoLink from '../links/FingoLink';
import { InvoiceType } from '../../propTypes';
import InvoiceCessionsTooltip from './InvoiceCessionsTooltip';
import { getSerializedSearch } from '../../helpers';

const FolioInvoiceProfileCell = ({ invoice, AlertsArray }) => {
  const component = useMemo(() => (
    <Stack justifyContent="center" display="flex">
      <InvoiceCessionsTooltip invoice={invoice}>
        <span>
          <FingoLink
            to={{
              pathname: '/app/invoice-profile',
              search: getSerializedSearch({
                invoiceId: parseInt(invoice.id, 10),
              }),
            }}
            textAlign="center"
            variant="body2"
            underline="hover"
            sx={{
              whiteSpace: 'nowrap',
            }}
          >
            {invoice.folio}
          </FingoLink>
        </span>
      </InvoiceCessionsTooltip>
      {AlertsArray && <AlertsArray invoice={invoice} />}
    </Stack>
  ), [invoice, AlertsArray]);
  return component;
};

FolioInvoiceProfileCell.propTypes = {
  invoice: InvoiceType.isRequired,
  AlertsArray: PropTypes.func,
};

FolioInvoiceProfileCell.defaultProps = {
  AlertsArray: null,
};

export default FolioInvoiceProfileCell;
