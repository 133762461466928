import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import Check from '@mui/icons-material/Check';
import Redo from '@mui/icons-material/Redo';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { indigo, green, amber } from '@mui/material/colors';
import { REINTEGRATION_FLAG_ACTIVATION } from '../../../graphql';
import LightningFastPayment from '../../tooltips/LightningFastPayment';
import { requestingPlatformMapperImage } from '../../../assets';
import AlertsGrid from '../../grids/AlertsGrid';

const ChileOperationalAlertsArray = ({ invoice }) => {
  const [updateReintegrationFlag] = useMutation(REINTEGRATION_FLAG_ACTIVATION, {
    variables: {
      managerId: invoice.collectionManager?.id,
      newStatus: !invoice.collectionManager?.forReintegration,
    },
  });
  const evaluationCode = invoice.preoffer
    ?.preofferevaluationrequest?.requestingPlatform?.code || invoice.operationalRoute;
  const operationCode = invoice.operation?.requestingPlatform?.code;
  const alertsArray = [];
  if (invoice.collectionManager) {
    alertsArray.push(
      {
        value: invoice.collectionManager?.forReintegration,
        label: 'reintegration',
        color: amber[700],
        tooltip: 'Pendiente de reintegro',
        icon: <Typography sx={{ fontSize: 10 }}>R</Typography>,
        onClick: updateReintegrationFlag,
      },
    );
  }
  if ('isForOrdering' in invoice) {
    alertsArray.push({
      value: invoice.isForOrdering,
      label: 'ordering',
      color: 'primary.main',
      tooltip: 'Descuento de Ordering',
      icon: <Typography sx={{ fontSize: 9 }}>OC</Typography>,
    });
  }
  if ('isFunding' in invoice) {
    alertsArray.push(
      {
        value: invoice.isFunding.value,
        label: 'funding',
        color: indigo.A400,
        tooltip: `Recedida a ${invoice.isFunding.socialReason}`,
        icon: <Redo sx={{ width: 12, height: 12 }} />,
      },
    );
  }
  if ('isRatificated' in invoice) {
    alertsArray.push(
      {
        value: invoice.isRatificated,
        label: 'ratification',
        color: green[500],
        tooltip: 'Factura ratificada',
        icon: <Check sx={{ width: 12, height: 12 }} />,
      },
    );
  }
  if (evaluationCode && evaluationCode !== 'FINGO') {
    alertsArray.push(
      {
        value: evaluationCode,
        label: 'externa',
        tooltip: 'Evaluada externamente',
        icon: <Avatar
          src={requestingPlatformMapperImage[evaluationCode]}
          sx={{ width: 15, height: 15 }}
        />,
      },
    );
  }
  if (operationCode && operationCode !== 'FINGO') {
    alertsArray.push(
      {
        value: operationCode,
        label: 'externa',
        tooltip: 'Operada externamente',
        icon: <Avatar
          src={requestingPlatformMapperImage[operationCode]}
          sx={{ width: 15, height: 15 }}
        />,
      },
    );
  }

  return (
    <AlertsGrid alerts={alertsArray}>
      {invoice.lightningPaymentAuthorized
      && <LightningFastPayment invoice={invoice} />}
    </AlertsGrid>
  );
};

ChileOperationalAlertsArray.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    lightningPaymentAuthorized: PropTypes.bool,
    collectionManager: PropTypes.shape({
      id: PropTypes.string.isRequired,
      forReintegration: PropTypes.bool,
    }),
    operationalRoute: PropTypes.string,
    isForOrdering: PropTypes.bool,
    isRatificated: PropTypes.bool,
    isFunding: PropTypes.shape({
      value: PropTypes.bool,
      socialReason: PropTypes.string,
    }),
    preoffer: PropTypes.shape({
      preofferevaluationrequest: PropTypes.shape({
        requestingPlatform: PropTypes.shape({
          code: PropTypes.string,
        }),
      }),
    }),
    operation: PropTypes.shape({
      requestingPlatform: PropTypes.shape({
        code: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default ChileOperationalAlertsArray;
